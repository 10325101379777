import React from 'react';
import { error as rollbarError } from '../../helpers/errors';
type State = { hasError: boolean };
type Props = { children: any };
class ErrorBoundary extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(/*error*/) {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }
  componentDidCatch(error, info) {
    // @ts-ignore
    rollbarError(error, info);
  }
  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      //TODO: You can render any custom fallback UI
      // return (
      //   <div>
      //     <h2>Oops, there is an error!</h2>
      //     <button type="button" onClick={() => this.setState({ hasError: false })}>
      //       Try again?
      //     </button>
      //   </div>
      // );
      return this.props.children;
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

export default ErrorBoundary;
