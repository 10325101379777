// @flow
import React from 'react';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { ApolloProvider } from '@apollo/client';
import { AnimateSharedLayout } from 'framer-motion';
import { useApollo } from '../helpers/apolloClient';
import {
  AuthProvider,
  SearchFilterProvider,
  ModalProvider,
  CategoriesProvider,
  PreviewProvider,
  RegisterProvider,
} from '../context';
import Layout from '../components/base/Layout';
import ErrorBoundary from '../components/errors/ErrorBoundary';
import SwiperCore, { Autoplay } from 'swiper';

import 'swiper/swiper-bundle.css';

import '../styles/app.sass';

SwiperCore.use([Autoplay]);

const App = ({ Component, pageProps }: AppProps) => {
  const apolloClient = useApollo(pageProps.initialApolloState);

  // const router = useRouter();

  // useEffect(() => {
  //   const handleRouteChange = (url: URL) => {
  //     gtag.pageview(url);
  //   };
  //   router.events.on('routeChangeComplete', handleRouteChange);
  //   return () => {
  //     router.events.off('routeChangeComplete', handleRouteChange);
  //   };
  // }, [router.events]);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,user-scalable=no,minimum-scale=1,maximum-scale=1"
        />
      </Head>
      <ErrorBoundary>
        <ApolloProvider client={apolloClient}>
          <AuthProvider>
            <SearchFilterProvider>
              <ModalProvider>
                <RegisterProvider>
                  <CategoriesProvider>
                    <PreviewProvider>
                      <Layout>
                        <AnimateSharedLayout>
                          <Component {...pageProps}></Component>
                        </AnimateSharedLayout>
                      </Layout>
                    </PreviewProvider>
                  </CategoriesProvider>
                </RegisterProvider>
              </ModalProvider>
            </SearchFilterProvider>
          </AuthProvider>
        </ApolloProvider>
      </ErrorBoundary>
    </>
  );
};

// export function reportWebVitals({ id, name, label, value }: NextWebVitalsMetric) {
//   window.gtag('event', name, {
//     event_category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
//     value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
//     event_label: id, // id unique to current page load
//     non_interaction: true, // avoids affecting bounce rate.
//   });
// }

export default App;
